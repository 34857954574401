<template>
  <vca-card>
    <vca-card class="text-center shadowed">
      <h1>{{ $t("messages.login.redirect") }}</h1>
    </vca-card>
  </vca-card>
</template>
<script>
export default {
  name: "RegisterView",
  data() {
    return {
      callbackRoute: "/",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.callbackRoute = from.path;
    });
  },
  mounted() {
    window.location.href =
      this.$store.getters["register_url"] +
      "?language=" +
      localStorage.language;
  },
};
</script>
